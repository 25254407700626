import React from "react"
import omenSmImg from "./omensm.jpg"
import styled from "styled-components"

const OmenSm = () => {
  return (
    <a href="https://www.omen.com/no/no/desktops.html?jumpid=ba_70a3b88021&utm_source=heroic&utm_medium=programmatic&utm_campaign=heroic-30l&utm_content=no022">
      <Outer>
        <img src={omenSmImg} alt="" />
      </Outer>
    </a>
  )
}

const Outer = styled.div``

export default OmenSm