import React from "react"
import styled from "styled-components"
import { Helmet } from "react-helmet"
import "./reset.css"
import logo from "./logo.png"
import OmenSm from "../../components/OmenSm"

const seo = {
  title: "Camp Heroic",
  description:
    "Heroic & Omen are bringing together Scandinavian influencers for some fun, strange and absurd challenges in Stockholm!",
  url: null,
  image: logo,
}

export default function Home() {
  return (
    <>
      <Helmet title={seo.title}>
        <meta name="description" content={seo.description} />
        {seo.url && <meta property="og:url" content={seo.url} />}
        {seo.title && <meta property="og:title" content={seo.title} />}

        {seo.description && (
          <meta property="og:description" content={seo.description} />
        )}
        {seo.image && <meta property="og:image" content={seo.image} />}
        {seo.title && <meta name="twitter:title" content={seo.title} />}
        {seo.description && (
          <meta name="twitter:description" content={seo.description} />
        )}
        {seo.image && <meta name="twitter:image" content={seo.image} />}
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href="/static/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/static/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/static/favicon-16x16.png"
        />
        <link rel="manifest" href="/static/site.webmanifest" />
        <link
          rel="mask-icon"
          href="/static/safari-pinned-tab.svg"
          color="#000000"
        />
        <link rel="shortcut icon" href="/static/favicon.ico" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-config" content="/static/browserconfig.xml" />
        <meta name="theme-color" content="#ffffff" />
      </Helmet>
      <div>
        <Header>
          <LogoImg src={logo} />
        </Header>
        <Content>
          <Hero>
            <Heading>CAMP HEROIC</Heading>
            <SubHeading>
              Heroic & Omen are bringing together four Nordic influencers for
              some fun, strange and absurd challenges in a new Youtube series!
            </SubHeading>
            <Intro>What else happens in Stockholm? The CSGO Major!</Intro>
            <Intro>
              We will combine esport and gaming challenges - on location in
              Stockholm. So stay tuned for participant reveals, giveaways and
              some of the funniest challenge videos ever produced across
              Scandinavia. Sweden, Norway and Denmark are going to war with
              eachother. Yet again!
            </Intro>
          </Hero>
          <OmenSm />
          <Influencers>
            <Influencer>
              {/* <InfluencerImage src="/placeholder-influencer.jpg" /> */}
              <InfluencerHeading>INFLUENCER #1</InfluencerHeading>
              <InfluencerBody>
                He is one of Sweden’s biggest gaming creators, but he streams
                and creates youtube videos in English. Each day. He plays A LOT
                of video games, but still fails a LOT. He might not be a talent,
                but he keeps grinding. Keeps laughing and spreads a ton of joy!
                Welcome ********
              </InfluencerBody>
            </Influencer>
            <Influencer>
              {/* <InfluencerImage src="/placeholder-influencer.jpg" /> */}
              <InfluencerHeading>INFLUENCER #2</InfluencerHeading>
              <InfluencerBody>
                He has started TWO of Norway’s most popular youtube channels.
                His reputation is a bit sketchy. Someone that will go far to win
                any challenge. Some say he is too comfortable in his own body.
                All we know is that he is called ***** ******. And that he is
                really really funny!
              </InfluencerBody>
            </Influencer>
            <Influencer>
              {/* <InfluencerImage src="/placeholder-influencer.jpg" /> */}
              <InfluencerHeading>INFLUENCER #3</InfluencerHeading>
              <InfluencerBody>
                She is the pride of two countries. An avid gamer with an
                atheltic past. She spreads joy and blows heads to pieces. All
                while exploring new lands and making friends, and enemies along
                the way.
              </InfluencerBody>
            </Influencer>
            <Influencer>
              {/* <InfluencerImage src="/placeholder-influencer.jpg" /> */}
              <InfluencerHeading>TOP SECRET</InfluencerHeading>
              <InfluencerBody>
                A masked person has entered the arena. More information to
                follow.
              </InfluencerBody>
            </Influencer>
          </Influencers>
          <Hero>
            <Heading>The series will premiere in November. Stay tuned!</Heading>
          </Hero>
          <OmenSm />
        </Content>
        <Footer>
          <FooterInner>
            <Svg
              viewBox="0 0 1029 498"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M514.26 70.8859L149.401 0.401915L514.26 456.477L879.119 0.401915L514.26 70.8859Z"
                fill="#ED1C24"
              />
              <path
                d="M316.692 263.467L300.468 260.583C300.133 260.531 299.799 260.454 299.463 260.403L183.469 239.722C183.469 239.722 229.491 310.648 268.481 317.602C269.151 317.73 269.821 317.834 270.465 317.962L347.983 331.766C348.396 279.872 333.277 267.794 316.692 263.467"
                fill="white"
              />
              <path
                d="M330.109 136.275C330.521 84.3821 315.404 72.3034 298.819 67.9767L282.595 65.0927C282.26 65.0407 281.925 64.9634 281.589 64.9114L0.221924 12.374C0.221924 12.374 46.2433 83.3007 85.235 90.2541C85.9042 90.3821 251.948 122.342 252.592 122.471L330.109 136.275"
                fill="white"
              />
              <path
                d="M728.052 260.583L711.827 263.467C695.241 267.794 680.124 279.872 680.537 331.766L758.055 317.962C758.699 317.834 759.369 317.73 760.039 317.602C799.029 310.648 845.051 239.722 845.051 239.722L729.056 260.403C728.721 260.454 728.387 260.531 728.052 260.583"
                fill="white"
              />
              <path
                d="M746.929 64.9114C746.595 64.9634 746.26 65.0407 745.925 65.0927L729.7 67.9767C713.115 72.3034 697.997 84.3821 698.411 136.275L775.928 122.471C776.572 122.342 942.616 90.3821 943.285 90.2541C982.276 83.3007 1028.3 12.374 1028.3 12.374L746.929 64.9114Z"
                fill="white"
              />
              <path
                d="M675.964 77.1817C675.964 77.1817 618.932 84.979 608.407 88.7523C600.211 91.691 578.347 99.4884 576.915 123.342C575.484 147.195 573.337 190.386 573.337 190.386L514.26 201.327L455.184 190.386C455.184 190.386 453.036 147.195 451.605 123.342C450.173 99.4884 428.309 91.691 420.113 88.7523C409.588 84.979 352.556 77.1817 352.556 77.1817L360.381 171.4L90.7814 123.342C90.7814 123.342 136.803 194.267 175.795 201.22C176.644 201.375 177.493 201.53 178.292 201.684L365.671 235.082L376.925 370.586L481.225 497.306C481.225 497.306 467.579 330.62 465.629 310.151C463.68 289.68 444.184 285.744 424.689 285.744V244.46L514.26 263.5L603.831 244.46V285.744C584.336 285.744 564.84 289.68 562.891 310.151C560.941 330.62 547.295 497.306 547.295 497.306L651.595 370.586L662.849 235.082L850.228 201.684C851.027 201.53 851.876 201.375 852.725 201.22C891.717 194.267 937.739 123.342 937.739 123.342L668.137 171.4L675.964 77.1817"
                fill="white"
              />
            </Svg>
            <BeHeroic>#BEHEROIC</BeHeroic>
          </FooterInner>
        </Footer>
      </div>
    </>
  )
}

const Header = styled.header`
  position: relative;
  height: 90px;
  background-color: #121212;
  display: flex;
  justify-content: center;
`

const LogoImg = styled.img`
  position: absolute;
  top: 24px;
  display: block;
  max-width: 120px;
`

const Content = styled.main`
  color: #121212;
  max-width: 970px;
  margin: 0 auto;
  display: grid;
  grid-row-gap: 96px;
  padding: 96px 12px 96px;
`

const Hero = styled.div`
  display: grid;
  grid-row-gap: 24px;
  text-align: center;
`

const Heading = styled.h1`
  font-size: 48px;
  text-transform: uppercase;
  font-weight: 700;
`

const SubHeading = styled.strong`
  font-size: 32px;

  @media (max-width: 719px) {
    font-size: 24px;
  }
`

const Intro = styled.p`
  font-size: 20px;

  @media (max-width: 719px) {
    font-size: 18px;
  }
`


const OmenBannerLg = styled.div`
  min-height: 640px;
  background-color: #121212;
`

const Influencers = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  grid-column-gap: 24px;
  grid-row-gap: 24px;

  @media (max-width: 589px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto auto;
  }
`

const Influencer = styled.div``

const InfluencerImage = styled.img`
  display: block;
  margin-bottom: 24px;
`

const InfluencerHeading = styled.h3`
  font-size: 32px;
`

const InfluencerBody = styled.p`
  font-size: 18px;
`

const Footer = styled.footer`
  background-color: #121212;
  min-height: 320px;
`

const FooterInner = styled.div`
  max-width: 970px;
  margin: 0 auto;
  padding-top: 36px;
`

const Svg = styled.svg`
  display: block;
  width: 140px;
  margin: 0 auto;
`

const BeHeroic = styled.h2`
  font-weight: 700;
  font-size: 36px;
  font-family: "Overpass";
  text-align: center;
  margin-top: 48px;
`